import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import "./404.scss"
import endofroad from "../images/endofroad.jpg"

const backgroundStyle = {
  backgroundImage: `url(${endofroad})`,
  backgroundRepeat: `none`,
  backgroundSize: `cover`,
  backgroundPosition: `center center`
}

const backToRoadmap = {
  textAlign: `center`,
}

const errorPage = (props) => (
  <Layout>
    <div className="img-background page404" style={backgroundStyle}>
      <div className="jumbotron-404">
        <div className={'title'}>Whoops !</div>
        <div className="content">
          This road is not on<br />
          any existing map !
        </div>
      </div>
      <div className="back-on-road" style={backToRoadmap}>
        <Link to={'/'} title={"Roadmap main page"} dangerouslySetInnerHTML={{__html: `Back to roadmap's home`}} />
        <a href={'https://sesf.ch'} title={'SeSF official website'} dangerouslySetInnerHTML={{__html: `SeSF official website`}} />
      </div>
    </div>
  </Layout>
)

export default errorPage